#login-panel button {
    width: 100%;
    margin-bottom: 3px;
    text-align: center;
}

#login-panel button.third-party-auth {
    padding-left: 33%;
    text-align: left;
}

#login-panel input {
    width: 100%;
    margin-bottom: 5px;
}

#login-panel p {
    width: 100%;
    text-align: center;
}

#login-panel {
/*    background-color: lightgray;*/
/*    border-left: 1px solid black;*/
}

.login-panel-left-image img {
/*    border-right: 1px solid black;*/
}

.login-panel-left-image img.d-md-none {
    /*    background-color: lightgray;*/
    border-right: none;
}

#login-panel button.half-width {
    width: 48%;
    margin-right: 10px;
}

#login-panel button.full-width-left {
/*    width: 100%;*/
    margin-right: 5px;
}

#login-panel button.full-width-right {
/*    width: 100%;*/
    margin-left: 5px;
}
