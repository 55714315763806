html {
    font-size: 14px;
    margin-top: 95px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

/* ************************* */



img.navbar-brand-logo {
    margin-top: -15px;
    margin-right: 10px;
/*    line-height: 40px;*/
}

.navbar-brand-text {
    line-height: 40px;
    font-size: 1.4em;
}

a.navbar-brand-link:active,
a.navbar-brand-link:hover,
a.navbar-brand-link:link,
a.navbar-brand-link:visited {
    color: black;
    text-decoration: none;
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
