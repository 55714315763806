.messages-view {
    border-left: 1px dotted black;
    background-color: white;
    padding: 5px;
    position: absolute;
    top: 0;
    bottom: 40px;
    left: 0;
    right: 0;
    overflow-y: scroll;
}

.messages-view-form {
}

#txtMessage {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 40px;
}

#cmdSend {
    position: absolute;
    bottom: 0;
    right: 0;
}

.messages-view-item-wrapper {
    padding: 0 10px;
}

.messages-view-item {
/*    align-self: center;
    right: 12px;*/
    clear: both;
}

.messages-view-item-date {
    font-size: 0.6em;
    margin: 0 10px;
}

.messages-view-item-text {
    /*position: relative;*/ /* for pseudos */
    font-size: 0.9rem;
    font-weight: normal;
    margin: 0;
    border: 2px solid black;
    border-radius: 15px;
    padding: 10px;
    min-height: 85px;
    /* max-width: 275px; */
}

.messages-view-item-self .messages-view-item-text {
    background-color: #def;
}

.messages-view-item-them .messages-view-item-text {
    background-color: #edf;
}

.messages-view-item-them img {
    float: left;
    margin: 10px;
    border-radius: 25% 10%;
}

.messages-view-item-self img {
    float: right;
    margin: 10px;
    border-radius: 10% 25%;
}

.messages-view-item-them {
    text-align: left;
    margin-top: 5px;
    margin-right: 40px;
    margin-bottom: 30px;
}

.messages-view-item-self {
    text-align: right;
    margin-top: 5px;
    margin-left: 40px;
    margin-bottom: 30px;
}

.messages-view .messages-view-empty {
    text-align: center;
    margin: 100px 20px;
}

#cmdNewConversationWhenEmpty {
    width: 100%;
}
