.messages-list-item {
    position: relative;
    min-height: 74px;
    padding: 5px;
    margin: 5px 0;
    background-color: #fafaff;
    clear: both;
    cursor: pointer;
}

.messages-list-item.active {
    background-color: #fafadd;
    border: 1px solid black;
    border-radius: 10px;
}

.messages-list-item-participants {
    margin-left: 74px;
    font-size: 0.9em;
    font-weight: bolder;
    overflow: hidden;
}

/* ***************************** */

.messages-list-item-unread {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: none;
    background-color: blue;
}

.messages-list-item-unread.unread {
    display: block;
}

/* ***************************** */

.messages-list-item-avatar {
    overflow: clip;
    float: left;
}

.messages-list-item-avatar img {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 64px;
    height: 64px;
}

/* ***************************** */

.messages-list-item-avatar .messages-list-item-avatar-1of2 img {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 32px;
    height: 64px;
    object-fit: cover;
}

.messages-list-item-avatar .messages-list-item-avatar-2of2 img {
    position: absolute;
    top: 5px;
    left: 37px;
    width: 32px;
    height: 64px;
    object-fit: cover;
}

/* ***************************** */

.messages-list-item-avatar .messages-list-item-avatar-1of3 img {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 32px;
    height: 64px;
    object-fit: cover;
}

.messages-list-item-avatar .messages-list-item-avatar-2of3 img {
    position: absolute;
    top: 5px;
    left: 37px;
    width: 32px;
    height: 32px;
}

.messages-list-item-avatar .messages-list-item-avatar-3of3 img {
    position: absolute;
    top: 37px;
    left: 37px;
    width: 32px;
    height: 32px;
}

/* ***************************** */

.messages-list-item-avatar .messages-list-item-avatar-1of4 img {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 32px;
    height: 32px;
}

.messages-list-item-avatar .messages-list-item-avatar-2of4 img {
    position: absolute;
    top: 5px;
    left: 37px;
    width: 32px;
    height: 32px;
}

.messages-list-item-avatar .messages-list-item-avatar-3of4 img {
    position: absolute;
    top: 37px;
    left: 5px;
    width: 32px;
    height: 32px;
}

.messages-list-item-avatar .messages-list-item-avatar-4of4 img {
    position: absolute;
    top: 37px;
    left: 37px;
    width: 32px;
    height: 32px;
}
