.stream-container {
    bottom: 100%;
}

.stream-content {
/*    background-color: lightcyan;*/
/*    height: 50%;*/
/*    bottom: 0;*/
/*    overflow-y: auto;*/
/*    overflow-x: hidden;*/
}

.stream-item hr {
    margin: 20px;
    margin-top: 0;
/*    margin-top: -20px;*/
}

.stream-item {
    margin-bottom: 15px;
/*    padding: 3px;*/
}

.stream-item-display-name {
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: bolder;
}

.stream-item-user-name {
    color: lightslategray;
}

.stream-item-post-date {
    font-size: 0.75em;
    color: lightslategray;
}

.stream-item-summary {
    margin: 10px 0;
}

.stream-item-buttons {
/*    margin-top: 5px;*/
}

.stream-item-score {
    font-size: 0.85em;
}

.stream-item-score-more {
    margin-top: -7px;
}

.stream-item-avatar {
    position: absolute;
    width: 64px;
    right: 0;
    border-radius: 25% 10%;
    border: 2px solid white;
}

.stream-item a:link,
.stream-item a:visited {
    text-decoration: none;
    color: black;
}

.stream-item a:hover,
.stream-item a:active {
    text-decoration: none;
    color: blue;
}

/*** Profile Header and Avatar ***/
.profile-header {
    margin-bottom: 30px;
}

.profile-header img.header {
    width: 100%;
}

.profile-header img.avatar {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 40px;
    top: 40px;
    border: 4px solid white;
    border-radius: 50%;
}

.header-name, .header-bio, .header-meta {
    margin: 10px 0;
}

.header-name-display {
    font-size: 1.2em;
    font-weight: bolder;
}

.header-name-user {
    font-size: 0.8em;
}

.header-bio {
    font-size: 0.9em;
}

.header-meta span {
/*    font-size: 0.75em;*/
    margin-right: 20px;
}

#cmdFollowProfile {
    position: absolute;
    right: 20px;
}