.image-right {
    float: right;
}

.image-left {
    float: left;
}

.content-image {
    width: 40%;
}

.content-image-full {
    width: 75%;
    margin: 20px;
}

.image-bordered {
    border: 1px solid black;
    border-radius: 3%;
}

