.messages-list {
    border-left: 1px dotted black;
    background-color: white;
    padding: 15px 5px;
    position: absolute;
    top: 39px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
}

#txtSearch {
    position: absolute;
    top: 0;
    left: 0;
    right: 85px;
}

#cmdSearch {
    position: absolute;
    top: 0;
    right: 45px;
}

#cmdNewConversation {
    position: absolute;
    top: 0;
    right: 0;
}
