.messages-view-item {
    clear: both;
}

.messages-view-item-date {
    font-size: 0.6em;
    margin: 0 10px;
}

.messages-view-item-text {
    /*position: relative;*/ /* for pseudos */
    font-size: 0.9rem;
    font-weight: normal;
    margin: 0;
    border: 2px solid black;
    border-radius: 15px;
    padding: 10px;
    min-height: 85px;
}

.messages-view-item-self .messages-view-item-text {
    background-color: #def;
}

.messages-view-item-them .messages-view-item-text {
    background-color: #edf;
}

.messages-view-item-them img {
    float: left;
    margin: 10px;
    border-radius: 25% 10%;
}

.messages-view-item-self img {
    float: right;
    margin: 10px;
    border-radius: 10% 25%;
}

.messages-view-item-them {
    text-align: left;
    margin-top: 5px;
    margin-right: 40px;
    margin-bottom: 30px;
}

.messages-view-item-self {
    text-align: right;
    margin-top: 5px;
    margin-left: 40px;
    margin-bottom: 30px;
}

/* ************************ */

/* https://stackoverflow.com/a/23584310/75696 */

div.unreadMarker {
    width: 100%;
    margin: 1.4em auto;
    overflow: hidden;
    text-align: center;
    font-weight: bolder;
    color: navy;
}

div.unreadMarker:before, div.unreadMarker:after {
    content: "";
    display: inline-block;
    width: 50%;
    margin: 0 .5em 0 -55%;
    vertical-align: middle;
    border-bottom: 1px solid;
}

div.unreadMarker:after {
    margin: 0 -55% 0 .5em;
}

div.unreadMarker span {
    display: inline-block;
    vertical-align: middle;
}