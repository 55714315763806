.sidebar-right.container,
.sidebar-right.container-fluid {
    position: sticky;
    top: 85px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.sidebar-right .title-row {
    border-bottom: 1px dashed lightgray;
}

.sidebar-right .last-row {
    border-top: 1px dashed lightgray;
    text-align: center;
    font-size: 0.8em;
    line-height: 3em;
    /*    position: sticky;*/
}

.sidebar-right .no-gutters {
    margin-bottom: 10px;
}

.sidebar-right .item-title {
    font-size: 1.5em;
    line-height: 2em;
    font-weight: bolder;
}

.sidebar-right .item-display-name {
    font-size: 0.9em;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    margin-top: -5px;
}

.sidebar-right .item-display-name a:link,
.sidebar-right .item-display-name a:visited {
    color: black;
    text-decoration: none;
}

.sidebar-right .item-user-name {
    color: lightslategray;
    text-decoration: none;
    font-size: 0.7em;
    margin-top: -3px;
    margin-bottom: 2px;
}

.sidebar-right .item-user-name a:link,
.sidebar-right .item-user-name a:visited {
    color: lightslategray;
    text-decoration: none;
}

.sidebar-right .item-user-name.item-user-name-promoted {
    color: black;
}

.sidebar-right .item-follows-you-div {
    font-size: 0.6em;
    margin-top: -1px;
    margin-bottom: -4px;
}

.sidebar-right .item-follows-you-span {
    font-size: 0.9em;
    color: white;
    background-color: cornflowerblue;
    padding: 2px 3px;
}

.sidebar-right .item-hide {
    display: none;
}

.sidebar-right .item-follow-button {
    margin-top: 10px;
    margin-left: -15px;
    width: 135%;
}

.sidebar-right .item-avatar {
    width: 45px;
    border-radius: 25% 10%;
}
