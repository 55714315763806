.subscriptions-page {
}

.content-head-left {
}

.content-head {
}

.content-col-head-1,
.content-col-head-2,
.content-col-head-3,
.content-col-head-4,
.content-col-head-5,
.content-col-head-6
{
    color: white;
    font-weight: bolder;
    text-align: center;
    padding: 5px;
    border-radius: 7px 7px 0 0;
}

.content-col-head-1 {
    background-color: #444444;
}

.content-col-head-2 {
    background-color: #D4370E;
}

.content-col-head-3 {
    background-color: #FAC308;
}

.content-col-head-4 {
    background-color: #107F16;
}

.content-col-head-5 {
    background-color: #1B6BD6;
}

.content-col-head-6 {
    background-color: #572EDD;
}

.content-col-1,
.content-col-2,
.content-col-3,
.content-col-4,
.content-col-5,
.content-col-6
{
    color: black;
    text-align: center;
    padding: 5px;
    border-radius: 0 0 7px 7px;
    font-size: 0.75em;
    font-weight: bold;
}

.content-col-1 {
    background-color: #DDDDDD;
}

.content-col-2 {
    background-color: #F8CABC;
}

.content-col-3 {
    background-color: #FEF0B8;
}

.content-col-4 {
    background-color: #BADBBF;
}

.content-col-5 {
    background-color: #BDD9F3;
}

.content-col-6 {
    background-color: #CEBDF8;
}

p.content-col-title {
    font-size: 1.3em;
    margin: 0;
}

p.content-col-price {
    font-size: 3.0em;
    margin: 0;
}

p.content-col-price-for {
    font-size: 0.6em;

}

button.subscribe-button {
    margin-top: 10px;
}