.sidebar-left {
    font-size: 1.3em;
/*    line-height: 1.5em;
    font-weight: bolder;*/
    position: fixed;
    top: 85px;
/*    bottom: 0;
    padding: 5px;*/
}

.sidebar-left a:hover,
.sidebar-left a:active,
.sidebar-left a:link,
.sidebar-left a:visited {
    color: black;
    text-decoration: none;
}

button.btn-lg.btn-rounded {
    border-radius: 40px;
}