html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    padding-top: 85px;
}

.tall {
    height: 100%;
}

#root, #root div.container {
    height: 99%;
    bottom: 0;
}

.hidden {
    display: none;
}